<template>
  <div>
    <el-dialog
      center
      title="拨打电话"
      :visible="visible"
      :modal-append-to-body="false"
      custom-class="ele-dialog-form"
      width="30%"
      @update:visible="updateVisible">

      <div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px">

          <el-row :gutter="15">
            <el-col :span="24">
              <el-form-item label="电话号码:" prop="phone">
                <el-input
                  clearable
                  v-model="form.phone"
                  placeholder="请输入电话号码"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <!--<el-button-->
        <!--  @click="updateVisible(false)">取消-->
        <!--</el-button>-->
        <!--<el-button-->
        <!--  style="margin-left: 20px;"-->
        <!--  type="primary"-->
        <!--  :loading="loading"-->
        <!--  @click="save">确定-->
        <!--</el-button>-->
        <div style="text-align: center;">
          <!--<el-button type="primary" @click="login(form.phone)">注册</el-button>-->
          <el-button type="success" @click="callPhone(form.phone)">拨打</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 通话嘟嘟嘟声 -->
    <audio id="conversation" ref="conversation">
      <source src="@/assets/sounds/1234.mp3" />
    </audio>

    <audio id="localVideo" ref="localVideo" src="@/assets/sounds/answer.mp3" />

    <audio class="audio" autoplay />

    <audio id="hangMusic" ref="hangMusic">
      <source src="@/assets/sounds/ringback.ogg" />
    </audio>

    <audio id="audioElement"></audio>

    <!--<el-dialog-->
    <!--  title="申请合作"-->
    <!--  :visible="cooperation"-->
    <!--  :modal-append-to-body="false"-->
    <!--  custom-class="ele-dialog-form"-->
    <!--  :before-close="handleClose"-->
    <!--  width="40%">-->
    <!--  <el-row :gutter="15" style="margin-bottom: 20px;">-->
    <!--    <el-col :span="12">-->
    <!--      <div style="background: #F8F9FB;padding: 20px;">-->
    <!--        <img style="width: 80px;height: 80px;border-radius: 50%;" src="@/assets/images/home/geren.png" alt="">-->
    <!--        <div>-->
    <!--        <span style="font-size: 18px;font-weight: 500;color: #707070;margin-left: 10px;">-->
    <!--          {{selectdata.company_name}}-->
    <!--        </span>-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          <i class="el-icon-location-outline"></i>-->
    <!--          <span style="margin-left: 10px;">{{selectdata.registration_place}}</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--    <el-col :span="12">-->
    <!--      <span>请输入验证信息：</span>-->
    <!--      <el-input-->
    <!--        style="margin-top: 5px;"-->
    <!--        type="textarea"-->
    <!--        clearable-->
    <!--        :autosize="{ minRows: 3, maxRows: 3}"-->
    <!--        placeholder="请输入内容"-->
    <!--        v-model="remark">-->
    <!--      </el-input>-->
    <!--      <div style="text-align: right;margin-top: 10px;">-->
    <!--        <el-button @click="handleClose">取消</el-button>-->
    <!--        <el-button type="primary" @click="confirm">确定</el-button>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--  </el-row>-->
    <!--</el-dialog>-->

  </div>
</template>

<script>

import Setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Bus from '@/utils/bus'
import Config from "@/config/index";

import JsSIP from "jssip";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 电话号码
    phone: String
  },
  data(){
    return {
      form:{
        phone:''
      },
      loading:false,

      // 验证规则
      rules: {
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      },



      Avatar: "",
      InCall: false,
      isg: false,
      callType: 1,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        customerName: "",
        calling: undefined,
        called: null,
        talkDate: null,
      },
      // 通话记录弹窗
      CallLog: false,
      tableData: [],
      topphone: "9051",
      a: "",
      show2: true,
      // isregister: false,
      callphonetrue: false,
      dialogVisible: false,
      ua: {},
      coolPhone: {},
      session: {},
      phonenumber: "",
      // phone: "",
      statue: "",
      // 来电号码
      callers: "",
      contents: "",
      lockReconnect: false, //是否真正建立连接
      timeout: 58 * 1000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      weburl: "webrtc.sxyygy.cn:1443",
      wsUri: 'wss://phone.89oa.cn:1443/wss',
      sipUri: 'sip:9051@phone.89oa.cn',
      pwd: "asd123456dfdsvT",
      timer: null,
      timerString: "00:00:00",
      Answering: false,


    }
  },

  mounted() {
    console.log(this.phone);
    this.form.phone = this.phone;

    // 默认进入就注册
    this.login('13103000257');
  },

  created() {
    // 开启 Socket链接
    // this.initWebSocket();
  },

  methods:{
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log('通过');
          // this.login(this.phone);
        } else {
          return false;
        }
      });
    },

    currentTime() {
      setInterval(this.formatDate, 100);
    },

    initWebSocket() {
      //初始化weosocket
      // const wsuri = "wss://xxxxxxx/xx/xx/" + this.roomId;
      this.websock = new WebSocket(
        `${process.env.VUE_APP_WS_API}/ydkj-admin/webSocket/${this.user.id}`
      );
      // this.websock = new WebSocket(wsuri);
      // 客户端接收服务端数据时触发
      this.websock.onmessage = this.websocketonmessage;
      // 连接建立时触发
      this.websock.onopen = this.websocketonopen;
      // 通信发生错误时触发
      this.websock.onerror = this.websocketonerror;
      // 连接关闭时触发
      this.websock.onclose = this.websocketclose;
    },
    // 连接建立时触发
    websocketonopen() {
      //开启心跳
      this.start();
      //连接建立之后执行send方法发送数据
      // let actions = {"room":"007854ce7b93476487c7ca8826d17eba","info":"1121212"};
      // this.websocketsend(JSON.stringify(actions));
    },
    // 通信发生错误时触发
    websocketonerror() {
      console.log("出现错误");
      this.reconnect();
    },
    // 客户端接收服务端数据时触发
    websocketonmessage(e) {
      console.log(e.data);
      //收到服务器信息，心跳重置
      this.reset();
      this.visible = true;
      // this.$store.dispatch("phone/isdotSetting", true);
      let a = JSON.parse(e.data);
      console.log(a, "a", eval(a));
      this.$confirm("您有新的待办需要处理!", "提示", {
        confirmButtonText: "去处理",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/myFlowable/tasktodo");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      // this.$notify.info({
      //   title: "提示",
      //   // message: message.title,
      //   duration: 0,
      //   position: "bottom-right",
      // });
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    // 连接关闭时触发
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      //重连
      this.reconnect();
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 100);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      console.log("开启心跳");
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.websock.readyState == 1) {
          //如果连接正常
          // self.ws.send("heartCheck"); //这里可以自己跟后端约定
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },


    /**
     * 分机注册，登录
     * @param {*} phoneNumber 分机号码
     */
    login(phone) {
      //创建一个 JsSIP User Agent
      if (phone == "" || !phone) {
        this.$message.error('请联系管理员分配分机号')
        return;
      }
      // console.log(this.weburl.split(":")[0]);
      // const wsUri = `wss://${this.weburl}/wss`; // Fs的 ws协议地址
      // const sipPwd = this.pwd; // FS密码
      // const sipUri =
      //   "sip:" + phone + `@${this.weburl.split(":")[0]};transport=wss`; // 分机号注册地址 格式 sip: + 分机号码 + @ + FS注册地址

      const wsUri = this.wsUri;
      const sipPwd = this.pwd;
      const sipUri = this.sipUri

      const configuration = {
        sockets: [new JsSIP.WebSocketInterface(wsUri)],
        uri: sipUri,
        password: sipPwd,
        session_timers: false, // 启用会话计时器（根据RFC 4028)
        user_agent: "Aegis WebRTC v1.0",
        contact_uri: sipUri,
        // outbound_proxy_set: wsUri,
        // display_name: String(phone),
        autostart: true, // 自动连接
        register: true, // 自动就绪
      };
      this.coolPhone = new JsSIP.UA(configuration);
      // this.statue = this.coolPhone.status;
      // Starting the User Agent
      this.setUAEvent();
      this.coolPhone.start();
    },


    setUAEvent(ua) {
      const log = console.log;

      // ws 开始尝试连接
      this.coolPhone.on("connecting", (args) => {
        // this.$notify({
        //   title: "提示",
        //   message: "开始尝试连接",
        //   duration: 500,
        // });
        log("开始尝试连接", args);
      });

      // ws 连接完毕
      this.coolPhone.on("connected", (e) => {
        // this.$notify({
        //   title: "提示",
        //   message: "连接完毕",
        //   duration: 500,
        // });
        log("连接完毕", e);
      });

      // ws 连接失败
      this.coolPhone.on("disconnected", (e) => {
        // this.$notify({
        //   title: "提示",
        //   message: "连接失败",
        //   duration: 500,
        // });
        log("连接失败", e);
      });

      // SIP 注册成功
      this.coolPhone.on("registered", (e) => {
        // this.$notify({
        //   title: "提示",
        //   message: "通话结束",
        //   duration: 500,
        // });
        // this.isregister = true;
        console.log("注册成功", e);
        // this.$store.dispatch("phone/isregisterSetting", true);
      });

      //  SIP 注册失败
      this.coolPhone.on("registrationFailed", (e) => {
        log("SIP注册失败", e);
        // this.isregister = false;
        // this.$store.dispatch("phone/isregisterSetting", false);
        console.log(this.isregister, "dispatch");
      });

      // SIP 取消注册
      this.coolPhone.on("unregistered", (e) => {
        log("SIP主动取消注册或注册后定期重新注册失败", e);
      });
      // IM消息 事件
      this.coolPhone.on("newMessage", (e) => log("im新消息事件"));
      // 来电或者外呼事件
      this.coolPhone.on("newRTCSession", (e) => {
        log(`新的${e.originator === "local" ? "外呼" : "来电"}`, e);
        const session = e.session;
        this.session = session;
        const peerconnection = session.connection;
        if (e.originator === "local") {
          // 打电话
          peerconnection.addEventListener("addstream", (event) => {
            const audio = document.querySelector(".audio");
            audio.srcObject = event.stream;
          });
          // 新增通话记录
          let data = {
            customerCode: this.customerCode,
            customerName: this.customerName,
            calling: this.topphone,
            callType: "0",
            called: this.callphone,
          };
          // workCallRecordDrawer(data).then((res) => { });
        } else {
          document.getElementById("localVideo").play();
          // 接电话
          this.dialogVisible = true;
          this.isg = true;
          this.callers = session.remote_identity.uri.user;
          // 新增通话记录
          let data = {
            customerCode: this.customerCode,
            customerName: this.customerName,
            calling: this.callers,
            callType: "1",
            called: this.topphone,
          };
          // workCallRecordDrawer(data).then((res) => { });
        }
        // 接通,在这一步可以处理音频播放
        // 接通并不代表对方已经接受，接通代表 滴 滴 滴
        session.on("confirmed", () => {
          console.log("接通中");
          // peerconnection.addEventListener("track", (event) => {
          //   const audioView = document.querySelector("#conversation");
          //   audioView.srcObject = event.streams[0];
          //   audioView.play();
          //   audioView.volume = 1;
          // });
          const audioElement = document.getElementById("audioElement");
          audioElement.autoplay = true;
          const stream = new MediaStream();
          const receivers = session.connection.getReceivers();
          if (receivers)
            receivers.forEach((receiver) => stream.addTrack(receiver.track));
          audioElement.srcObject = stream;
          // 最后都要播放
          audioElement.play();
        });

        // 接听失败
        session.on("failed", (mdata) => {
          this.Answering = false;
          this.timerString = "00:00:00";
          const myAuto = document.getElementById("localVideo");
          myAuto.pause();
          myAuto.load();
          // 嘟嘟嘟
          const conversation = document.getElementById("conversation");
          conversation.pause();
          conversation.load();
          log("来电的时候 拒接或者 还没接听对方自己就挂断了");
          this.$notify({
            title: "提示",
            message: "通话结束",
            duration: 1000,
          });
          this.InCall = false;
          this.dialogVisible = false;
          // this.$store.dispatch("phone/userinfoPhoneSetting", {});
          // this.$store.dispatch("phone/isdaSetting", false);
          // this.$store.dispatch("phone/callphoneSetting", "");
          // this.$store.dispatch("phone/customerCodeSetting", "");
          // this.$store.dispatch("phone/customerNameSetting", "");
        });

        // 接听成功
        session.on("accepted", (response, cause) => {
          // 嘟嘟嘟
          const conversation = document.getElementById("conversation");
          conversation.pause();
          conversation.load();
          this.Answering = true;
          log("接听成功");
          let origin = new Date().getTime();
          console.log(5855);
          this.restoreTime(origin);
        });
        // 接听成功后 挂断
        session.on("ended", () => {
          this.Answering = false;
          this.timerString = "00:00:00";
          // 嘟嘟嘟
          const conversation = document.getElementById("conversation");
          conversation.pause();
          conversation.load();
          this.$store.dispatch("phone/userinfoPhoneSetting", {});
          this.$store.dispatch("phone/isdaSetting", false);
          this.$store.dispatch("phone/callphoneSetting", "");
          this.$store.dispatch("phone/customerCodeSetting", "");
          this.$store.dispatch("phone/customerNameSetting", "");

          this.$notify({
            title: "提示",
            message: "通话结束",
            duration: 1000,
          });

          this.dialogVisible = false;
          this.InCall = false;
        });
        // 通话被挂起
        session.on("hold", (data) => {
          const org = data.originator;
          if (org === "local") {
            log("通话被本地挂起:", org);
          } else {
            // log("通话被远程挂起:", org);
          }
        });
        // 通话被继续
        session.on("unhold", (data) => {
          const org = data.originator;
          if (org === "local") {
            // );
          } else {
            // log("通话被远程继续:", org);
          }
        });
      });
    },

    //参数为时间差秒数，返回这两个时间差并格式化
    computeTimeDiff(diff) {
      diff = Math.round(diff / 1000);
      let hour = Math.floor(diff / 3600)
        .toString()
        .padStart(2, "0");
      let min = Math.floor((diff - hour * 3600) / 60)
        .toString()
        .padStart(2, "0");
      let sec = (diff % 60).toString().padStart(2, "0");
      return hour + ":" + min + ":" + sec;
    },

    //重置时间
    restoreTime(origin) {
      clearInterval(this.timer);
      this.timerString = "00:00:00";
      this.timer = setInterval(() => {
        this.timerString = this.computeTimeDiff(new Date().getTime() - origin);
      }, 1000);
    },


    /**
     * 拨打
     * @param {*} phoneNumber 拨打号码
     */
    callPhone(phoneNumber) {
      const data = {
        // methods: "GET",
        // url: "http://gy.89oa.cn:880/uncall_api/webapi_v1.php",
        params: {
          method: "4",
          // strAgent: this.topphone,
          agent: this.topphone,
        },
      };
      if (phoneNumber == "") {
        this.$message.error('请输入拨打号码')
        return;
      }

      this.callers = this.trimAll(phoneNumber);

      const log = console.log;
      const options = {
        pcConfig: {
          rtcpMuxPolicy: "negotiate",
          // iceServers: [],
        },
        rtcOfferConstraints: {
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 0,
        },
        eventHandlers: {
          progress(e) {
            // document.getElementById("hangMusic").play();
            document.getElementById("conversation").play();
            log("正在呼叫:", e);
            // store.dispatch(setCallStatus('calling'));
          },
          failed(e) {
            // 嘟嘟嘟
            const conversation = document.getElementById("conversation");
            conversation.pause();
            conversation.load();
            log("呼叫失败: ", e);
          },
          ended(e) {
            // 嘟嘟嘟
            const conversation = document.getElementById("conversation");
            conversation.pause();
            conversation.load();
            log(
              "呼叫结束:" + e.originator === "remote"
                ? "对方挂断"
                : "自己挂断",
              e
            );
          },
          confirmed(e) {
            // 嘟嘟嘟
            const conversation = document.getElementById("conversation");
            conversation.pause();
            conversation.load();
            log(
              "呼叫接受" + e.originator === "remote"
                ? "自己已接受"
                : "对方已接受",
              e
            );
            // store.dispatch(setCallStatus('confirmed'));
          },
        },
        mediaConstraints: {
          audio: true,
          video: false,
        },
      };
      this.coolPhone.call(`sip:${this.trimAll(phoneNumber)}`, options);

    },

    trimAll(ele) {
      if (typeof ele === "string") {
        return ele.split(" ").join("");
      } else {
        console.error(
          `${typeof ele} is not the expected type, but the string type is expected`
        );
      }
    },



  }

}
</script>
